import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { timestamp: String, i18nDay: String };
  static targets = ['days', 'hours', 'minutes', 'seconds'];

  connect () {
    this.timestamp = (new Date()).setTime(parseInt(this.timestampValue));

    this.nums = {};
    this.i18nDay = this.i18nDayValue.split('|');

    window.setInterval(this.calculcate.bind(this), 1000);
    this.calculcate();
  }

  calculcate () {
    const now = new Date().getTime();
    let remaining = parseInt((now < this.timestamp ? (this.timestamp - now) : (now - this.timestamp)) / 1000);

    if (remaining <= 0) return;
    
    this.nums.days = parseInt(remaining / 86400);
    remaining = remaining % 86400;
    
    this.nums.hours = parseInt(remaining / 3600);
    remaining = remaining % 3600;
    
    this.nums.minutes = parseInt(remaining / 60);
    remaining = remaining % 60;
    
    this.nums.seconds = parseInt(remaining);
    
    this.nums.days    = parseInt(this.nums.days, 10);
    this.nums.hours   = this.nums.hours < 10 ? `0${this.nums.hours}` : this.nums.hours;
    this.nums.minutes = this.nums.minutes < 10 ? `0${this.nums.minutes}` : this.nums.minutes;
    this.nums.seconds = this.nums.seconds < 10 ? `0${this.nums.seconds}` : this.nums.seconds;

    this.draw();
  }

  draw () {
    this.element.innerHTML = `${this.nums.days} ${this.i18nDay[this.nums.days == 1 ? 0 : 1]} ${this.nums.hours}:${this.nums.minutes}:${this.nums.seconds}`;
  }
}
