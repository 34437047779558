import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect () {
    this.shortcut();
  }

  shortcut () {
    this.element.querySelector('textarea').addEventListener('keydown', (e) => {
      if ((e.metaKey || e.ctrlKey) && e.keyCode === 13) {
        this.element.dispatchEvent(new Event('submit', { bubbles: true, cancelable: true })); // yes ...
      }
    });
  }
}
