import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { dataName: String, fileName: String };

  // connect () {
  // }

  dl (e) {
    e.preventDefault();

    const data = window.$Gunfinder.csvDl[this.dataNameValue].map((line) => line.join(',')).join('\n');

    const
      blob = new Blob([data], { type: 'text/plain' }),
      url  = URL.createObjectURL(blob),
      link = document.createElement('a')
    ;

    link.href     = url;
    link.download = `${this.fileNameValue}.csv`;

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
}
