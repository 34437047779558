import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    max: Number,
    step: {
      type: Number,
      default: 0
    },
    interval: {
      type: Number,
      default: 10
    }
  };

  connect () {
    const observer = new IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) return;
      observer.unobserve(this.element);
      this.count();
    }, { threshold: [1] });

    observer.observe(this.element);
  }

  count () {
    this.currentVal = 0;

    const timer =  window.setInterval(() => {
      let nextVal = this.currentVal + this.stepValue;

      if (nextVal >= this.maxValue) {
        nextVal = this.maxValue;
        clearInterval(timer);
      }

      this.currentVal = nextVal;
      this.element.innerText = new Intl.NumberFormat('de-DE', { minimumFractionDigits: Number.isInteger(this.maxValue) ? 0 : 1 }).format(this.currentVal);

    }, this.intervalValue);
  }
}





// const counters = document.querySelectorAll('.value');
// const speed = 200;

// counters.forEach( counter => {
//    const animate = () => {
//       const value = +counter.getAttribute('akhi');
//       const data = +counter.innerText;
     
//       const time = value / speed;
//      if(data < value) {
//           counter.innerText = Math.ceil(data + time);
//           setTimeout(animate, 1);
//         }else{
//           counter.innerText = value;
//         }
     
//    }
   
//    animate();
// });
