import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    latitude: Number,
    longitude: Number
  };

  connect () {
    window.initMap = () => {
      var map = new google.maps.Map(this.element, {
        gestureHandling: 'cooperative',
        streetViewControl: false,
        mapTypeControl: false,
        keyboardShortcuts: false
      });

      const
        marker = new google.maps.Marker({
          position: {
            lat: parseFloat(this.latitudeValue),
            lng: parseFloat(this.longitudeValue)
          },
          map: map
        })
      ;

      map.setZoom(10);
      map.panTo(marker.position);
    };
  }

  load (e) {
    e.currentTarget.remove();
    this.element.classList.remove('d-none');

    var script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyB4tr5SS3LwHjvDFoKrQzeKKv_R58MFlQc&callback=initMap';

    document.getElementsByTagName('head')[0].appendChild(script);
  }
}
