import * as Sentry from '@sentry/browser';

export default function initSentry (dsn) {
  window.Sentry = Sentry;

  Sentry.init({
    dsn,
    environment: window.$Gunfinder.env,
    release: window.$Gunfinder.version,
    integrations: [
      // window.Sentry.captureConsoleIntegration(), // sends all console.log as a new event
      window.Sentry.contextLinesIntegration(),
      window.Sentry.extraErrorDataIntegration(),
      window.Sentry.httpClientIntegration(),
    ],
    sendDefaultPii: true, // window.Sentry.httpClientIntegration option
    // https://docs.sentry.io/platforms/javascript/configuration/filtering/
    // https://gist.github.com/impressiver/5092952
    allowUrls: [
      /https:\/\/((cdn|www)\.)?gunfinder\.(de|at|ch|com)/
    ],
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      'translate.goo',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
      // Avast extension error
      '_avast_submit'
    ]
  });
}
