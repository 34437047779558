import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'ghost'];

  connect () {
    this.buttonTarget.addEventListener('click', (e) => {
      this.ghostTarget.classList.remove('d-none');
      this.buttonTarget.remove();
    });  
  }
}
