import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['input', 'form'];
  static values = {
    mode: {
      type: String,
      default: 'embed'
    },
    selection: Array
  };

  connect () {
    this.selection = this.selectionValue;
    this.select();

    if (this.modeValue === 'modal') {
      this.modal = window.bootstrap.Modal.getOrCreateInstance(this.element);
      this.formTarget.addEventListener('submit', () => this.modal.hide());

      this.modal.show();
    }
  }

  select () {
    for (const interest of this.selection) {
      const target = this.element.querySelector(`[data-interest-cloud-interest-param="${interest}"]`);
      if (!target) continue; // ...

      target.classList.remove('bg-primary-subtle');
      target.classList.add('bg-primary');
    }
  }

  toggle (e) {
    const
      intruder = e.params.interest,
      target = e.currentTarget
    ;

    if (this.selection.indexOf(intruder) === -1) {
      this.selection.push(intruder);
    } else {
      this.selection = this.selection.filter(interest => interest !== intruder);
    }

    target.classList.toggle('bg-primary-subtle');
    target.classList.toggle('bg-primary');

    this.inputTarget.value = this.selection.join(',');
  }

  save () {

  }
}
