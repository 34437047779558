import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect () {
    if (window.location.hash) {
      const item = document.querySelector(`[data-bs-target="${window.location.hash}"]`);
      if (item) {
        item.click();
      }
    }
  }
}
