import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['moreButton', 'lessButton', 'shortComment', 'comment', 'ratings'];

  open (e) {
    e.preventDefault();

    this.shortCommentTarget.classList.add('d-none');
    this.commentTarget.classList.remove('d-none');
    this.moreButtonTarget.classList.add('d-none');
    this.lessButtonTarget.classList.remove('d-none');

    if (this.hasRatingsTarget) {
      this.ratingsTarget.classList.remove('d-none');
    }
  }

  close (e) {
    e.preventDefault();

    this.shortCommentTarget.classList.remove('d-none');
    this.commentTarget.classList.add('d-none');
    this.moreButtonTarget.classList.remove('d-none');
    this.lessButtonTarget.classList.add('d-none');

    if (this.hasRatingsTarget) {
      this.ratingsTarget.classList.add('d-none');
    }
  }
}
