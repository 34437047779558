import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['favoriteButton'];
  static values = { id: Number };

  get appController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('body'), 'app');
  }

  auth (e) {
    if (window.$Gunfinder.user.signedIn) {
      return;
    }

    e.preventDefault();

    const path = e.currentTarget.href; // e is not available in callback
    this.appController.authController.open({ mode: 'sell', callback () {
      window.Turbo.visit(path);
    } });
  }
}
