import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['toggleA', 'toggleB', 'a', 'b'];

  connect () {
    this.activeToggle = 'a';
  }

  activateToggle (aorb) {
    if (aorb === 'a' && this.activeToggle !== 'a') {
      const classList = [...this.toggleATarget.classList];
      this.toggleATarget.classList.add(...this.toggleBTarget.classList);
      this.toggleBTarget.classList.remove(...this.toggleBTarget.classList);
      this.toggleBTarget.classList.add(...classList);

      this.activeToggle = 'a';
    }

    if (aorb === 'b' && this.activeToggle !== 'b') {
      const classList = [...this.toggleBTarget.classList];
      this.toggleBTarget.classList.add(...this.toggleATarget.classList);
      this.toggleATarget.classList.remove(...this.toggleATarget.classList);
      this.toggleATarget.classList.add(...classList);

      this.activeToggle = 'b';
    }
  }

  activateA () {
    this.activateToggle('a');

    for (const el of this.aTargets) {
      el.classList.remove('d-none');
    }

    for (const el of this.bTargets) {
      el.classList.add('d-none');
    }
  }

  activateB () {
    this.activateToggle('b');

    for (const el of this.aTargets) {
      el.classList.add('d-none');
    }

    for (const el of this.bTargets) {
      el.classList.remove('d-none');
    }
  }
}
