import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  positive () {
    window.$Gunfinder.track('feedback: do you like gf?', { answer: 'yes' });
  }

  negative () {
    window.$Gunfinder.track('feedback: do you like gf?', { answer: 'no' });
  }
}
