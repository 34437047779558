import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['switcher'];

  connect () {
    this.updateSwitcher.bind(this);

    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', () => {
      const scheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';

      if (this.scheme === 'system')
        this.colorScheme = scheme;
    });

    window.addEventListener('DOMContentLoaded', () => {
      document.documentElement.setAttribute('data-bs-theme', this.colorScheme);
    });
    
    this.updateSwitcher(this.scheme);
  }

  get schemes () {
    return {
      system: {
        name: 'Auto',
        icon: 'circle-half'
      },
      light: {
        name: 'Light',
        icon: 'sun-fill'
      },
      dark: {
        name: 'Dark',
        icon: 'moon-stars-fill'
      }
    }
  }

  get scheme () {
    const scheme = window.localStorage.getItem('scheme');
    return scheme ? scheme : 'system';
  }

  set scheme (scheme) {
    window.localStorage.setItem('scheme', scheme);
    this.colorScheme = scheme;
  }

  set colorScheme (scheme) {
    const colorScheme = this.colorSchemeByName(scheme);
    document.documentElement.setAttribute('data-bs-theme', colorScheme);
  }

  get colorScheme () {
    return this.colorSchemeByName(this.scheme);
  }

  colorSchemeByName (name) {
    return name !== 'system' ? name : window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  }

  update (e) {
    const scheme = e.currentTarget.dataset.scheme;
    this.scheme = scheme;
    this.updateSwitcher(scheme);
  }
  
  updateSwitcher (scheme) {
    if (!this.hasSwitcherTarget) return;
    
    const el = this.element.querySelector(`[data-scheme="${scheme}"]`);
    this.switcherTarget.innerHTML = el.innerHTML;
  }
}
