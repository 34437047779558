import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['chunk', 'left', 'right']

  connect () {
    this.chunk = 0;
    this.buttons();
  }

  left () {
    if (this.chunk === 0)
      return;

    this.chunk -= 1;
    this.show();
    this.buttons();
  }

  right () {
    if (this.chunk === this.chunkTargets.length - 1)
      return;

    this.chunk += 1;
    this.show();
    this.buttons();
  }

  show () {
    this.chunkTargets[this.chunk].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'center' });
  }

  buttons () {
    if (this.chunk === 0) {
      this.leftTarget.classList.add('disabled');
    } else {
      this.leftTarget.classList.remove('disabled');
    }

    if (this.chunk === this.chunkTargets.length - 1) {
      this.rightTarget.classList.add('disabled');
    } else {
      this.rightTarget.classList.remove('disabled');
    }
  }
}
