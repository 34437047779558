import { Controller } from '@hotwired/stimulus';
import xhook from 'xhook';

export default class extends Controller {
  connect () {
    this.renewVerificationModalOpen = false;

    this.xhr();
    this.turbo();
  }

  openRenewVerificationModal () {
    window.bootstrap.Modal.getOrCreateInstance('#renew-verification-modal').show();
    // (window.bootstrap.Modal.getOrCreateInstance(document.getElementById('renew-verification-modal'))).show();
    this.renewVerificationModalOpen = true;
  }

  xhr () {
    xhook.after((request, response) => {
      if (this.renewVerificationModalOpen) return;

      if (response.status === 424) {
        this.openRenewVerificationModal();
      }
    });
  }

  turbo () {
    for (const el of document.querySelectorAll('html, turbo-frame, form')) {
      el.addEventListener('turbo:before-fetch-response', (e) => {
        if (this.renewVerificationModalOpen) return;

        if (e.detail?.fetchResponse?.response?.status === 424) {
          this.openRenewVerificationModal();
        }
      })
    }
  }
}
