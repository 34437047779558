import { Controller } from '@hotwired/stimulus';
import Bss from '@erwinstone/bs-select';
// import Bss from './bss.js';

export default class extends Controller {
  connect () {
    this.dropdown = new Bss(this.element, {
      labels: {
        search: 'Suchen ...',
        noResults: 'Keine Ergebnisse'
      }
    });

    this.element.addEventListener('reset', () => {
      this.dropdown.clear();
    });

    this.element.addEventListener('enable', () => {
      this.dropdown.enable();
    });

    this.element.addEventListener('disable', () => {
      this.dropdown.disable();
    });
  }
}
