import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    type: {
      type: String,
      default: 'line'
    },
    data: Object,
    options: Object,
  }

  connect () {
    this.loadChartJs(this.init.bind(this));
  }

  init () {
    this.setDefaults();

    new window.Chart(this.element, {
      type: this.typeValue,
      data: this.dataValue,
      options: this.optionsValue,
    });
  }

  loadChartJs (onLoad) {
    const el = document.createElement('script');
    el.onload = onLoad;
    el.src = 'https://cdn.jsdelivr.net/npm/chart.js';

    document.body.append(el);
  }

  setDefaults () {
    window.Chart.defaults.locale = 'de';
    window.Chart.defaults.responsive = true;
    window.Chart.defaults.font.family = 'Lato';
    window.Chart.defaults.plugins.legend.align = 'start';
    window.Chart.defaults.plugins.legend.position = 'bottom';
    window.Chart.defaults.plugins.title.font.size = '16px';
    window.Chart.defaults.plugins.title.padding = 0;
    window.Chart.defaults.plugins.subtitle.padding = { bottom: 20 };
  }
}
