import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    if (window.localStorage.gunfinderLocaleSwitcher === undefined) {
      this.element.classList.remove('d-none');
    }
  }

  async dismiss () {
    this.element.remove();
    window.localStorage.gunfinderLocaleSwitcher = false;
  }
}
