window.$Gunfinder.Adhunter = {
  load () {
    if (!window.reviveAsync) return;

    const key = Object.keys(window.reviveAsync)[0];
    window.reviveAsync[key].apply(window.reviveAsync[key].detect());

    // window.setInterval(window.$Gunfinder.refresh, 30 * 1000);
  },

  refresh () {
    for (const el of document.querySelectorAll('[data-revive-loaded]')) {
      el.removeAttribute('data-revive-loaded');
    }

    const key = Object.keys(window.reviveAsync)[0];
    window.reviveAsync[key].refresh()
  }
}
