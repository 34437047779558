import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect () {
    this.oembed();
    this.pdf();
  }

  script (src, onload) {
    const script = document.createElement('script');
    script.src = src;
    script.setAttribute('async', true);
    script.onload = onload;
    document.head.appendChild(script);
  }

  oembed () {
    const onload = () => {
      for (const oembed of this.element.querySelectorAll('oembed[url]')) {
        window.iframely.load(oembed, oembed.getAttribute('url'));
      }
    };

    if (this.element.querySelectorAll('oembed[url]').length > 0) {
      this.script('https://cdn.iframe.ly/embed.js?api_key=4d88a2f82992808e48f865', onload);
    }
  }

  pdf () {
    const onload = () => {
      for (const el of this.element.querySelectorAll('.pdfobject')) {
        PDFObject.embed(el.getAttribute('data-url'), el, {
          fallbackLink: `<div class="text-center"><a href="${el.getAttribute('data-url')}" class="btn btn-warning">Datei ansehen</a></div>`,
          height: '25rem'
        });
      }
    };

    if (this.element.querySelectorAll('.pdfobject').length > 0) {
      this.script('https://cdnjs.cloudflare.com/ajax/libs/pdfobject/2.2.6/pdfobject.min.js', onload);
    }
  }
}
