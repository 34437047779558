import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['trigger', 'tab'];
  static values = {
    inactiveClass: {
      type: Array,
      default: ['btn-light']
    },
    activeClass: {
      type: Array,
      default: ['btn-primary']
    }
  };

  connect () {
    this.currentId = 0;

    for (const [i, trigger] of this.triggerTargets.entries()) {
      trigger.addEventListener('click', (e) => {
        e.preventDefault();
        this.toggle(i);
      });
    }

    if (window.location.hash) {
      const hashMatch = window.location.hash.match(/#tab(\d)/i);
      if (hashMatch) {
        const hashTab = parseInt(hashMatch[1]);
        if (hashTab > 0 && hashTab <= this.triggerTargets.length) {
          this.triggerTargets[hashTab - 1].click();
        }
      }
    }
  }

  toggle (id) {
    if (this.currentId === id) return;

    this.triggerTargets[id].classList.remove(...this.inactiveClassValue);
    this.triggerTargets[id].classList.add(...this.activeClassValue);

    this.triggerTargets[this.currentId].classList.add(...this.inactiveClassValue);
    this.triggerTargets[this.currentId].classList.remove(...this.activeClassValue);


    this.tabTargets[id].classList.remove('d-none');
    this.tabTargets[this.currentId].classList.add('d-none');

    this.currentId = id;
  }
}
