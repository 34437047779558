import { Openpanel } from '@openpanel/web';
 
const op = new Openpanel({
  clientId: window.$Gunfinder.keys.openpanel,
  trackScreenViews: true,
  trackAttributes: true,
  trackOutgoingLinks: true,
});

if (!window.$Gunfinder.user.virtual && window.$Gunfinder.user.signedIn) {
  op.setProfileId(window.$Gunfinder.user.id);
}

window.openpanel = op;
