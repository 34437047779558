import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { url: String };
  static targets = ['q', 'exact', 'exclude', 'url'];

  submit (e) {
    e.preventDefault();
  }

  search (e) {
    e.preventDefault();

    let query = this.qTarget.value;

    if (this.exactTarget.value !== '') {
      query += (query === '' ? '' : ' ') + '"' + this.exactTarget.value + '"';
    }

    if (this.excludeTarget.value !== '') {
      query += (query === '' ? '' : ' ') + '-' + this.excludeTarget.value.split(' ').join(' -');
    }

    if (query && query !== '') {
      Turbo.visit(this.urlValue.replace('_query_', encodeURIComponent(query)));
    }
  }
}
