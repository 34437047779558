import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['rating', 'details', 'comment'];

  get appController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('body'), 'app');
  }

  connect () {
    this.showDetails();
  }

  rate (e) {
    if (!window.$Gunfinder.user.signedIn) {
      this.appController.authController.open({ mode: 'user' });
      e.preventDefault();
      return;
    }

    this.showDetails();
  }

  showDetails () {
    let rated = true;
    for (const rating of this.ratingTargets) {
      if (rating.querySelectorAll('input:checked').length === 0){
        rated = false;
      }
    }

    if (rated) {
      this.detailsTarget.classList.remove('d-none');

      window.setTimeout(() => {
        this.commentTarget.focus();
      }, 100);
    }
  }
}
