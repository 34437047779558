import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { messagePath: String, meta: Object };

  connect () {
    if (window.$Gunfinder.user.signedIn) {
      if (document.getElementById('bid-modal')) {
        this.bidModal = window.bootstrap.Modal.getOrCreateInstance(document.getElementById('bid-modal'));

        document.getElementById('bid-modal').addEventListener('shown.bs.modal', () => {
          document.getElementById('bid_value')?.focus();
        });
      }
      
      this.messageModal = window.bootstrap.Modal.getOrCreateInstance(document.getElementById('message-modal'));

      document.getElementById('message-modal').addEventListener('show.bs.modal', () => {
        window.setTimeout(() => { document.getElementById('message_body').focus() }, 500);
      });
    }
    
    if (window.$Gunfinder.user.signedIn) {
      if (window.location.hash === '#bid') {
        this.bidModal?.show();
      }

      if (window.location.hash === '#message') {
        this.messageModal?.show();
      }
    }
  }

  get appController () {
    return this.application.getControllerForElementAndIdentifier(document.querySelector('body'), 'app');
  }

  message () {
    if (this.messagePathValue != '') {
      window.Turbo.visit(this.messagePathValue);
      return;
    }
    
    if (!window.$Gunfinder.user.signedIn) {
      this.appController.authController.open({ mode: 'auction-message', redirectPath: `${window.location.pathname}#message` });
      return;
    }

    this.messageModal.show();
  }

  bid () {
    window.$Gunfinder.track('auctions: internal clicked', this.metaValue);

    if (this.messagePathValue != '') {
      window.Turbo.visit(this.messagePathValue);
      return;
    }

    if (!window.$Gunfinder.user.signedIn) {
      this.appController.authController.open({ mode: 'auction-bid', redirectPath: `${window.location.pathname}#bid` });
      return;
    }

    this.bidModal.show();
  }
}
