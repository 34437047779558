import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    autoOpen: false
  }

  connect () {
    this.modal = window.bootstrap.Modal.getOrCreateInstance(this.element);
    if (this.autoOpenValue) this.open();
  }

  open () {
    this.modal.show();
  }
}

