import { Controller } from '@hotwired/stimulus';

// expects to reconnect after every submit
export default class extends Controller {
  static values = {
    autosubmit: {
      type: Boolean,
      default: false
    },
    action: {
      type: String,
      default: '',
    }
  }

  connect () {
    this.element.addEventListener('submit', this.signAndSubmit.bind(this), { once: true });
    if (this.autosubmitValue) this.signAndSubmit();
  }

  async signAndSubmit (e) {
    e?.preventDefault();
    // e.stopPropagation();
    
    // ppl double click and thereby fuck up the token generation
    // for (const el of this.element.querySelectorAll('button, input[type="button"], input[type="submit"]')) {
    //   el.disabled = true;
    // }

    const attach = (name, token) => {
      if (this.element.querySelector(`input[name="${name}"]`)) {
        this.element.querySelector(`input[name="${name}"]`).value = token;
      } else {
        const input = document.createElement('input');
        input.type  = 'hidden';
        input.name  = name;
        input.value = token;
        this.element.appendChild(input);
      }
    };

    attach('castle_request_token', await window.castle.createRequestToken());
    
    window.setTimeout(() => {
      this.element.requestSubmit(e?.submitter);
    }, 0);
  }
}
