window.$Gunfinder = window.$Gunfinder || {};

window.$Gunfinder.fetch = async (resource, options) => {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').content;

  if (options && options.headers) {
    if (!options.headers['X-CSRF-Token'])
      options.headers['X-CSRF-Token'] = csrfToken;

    if (!options.headers['X-Requested-With'])
      options.headers['X-Requested-With'] = 'XMLHttpRequest';
  } else if (options && !options.headers) {
    options.headers = { 'X-CSRF-Token': csrfToken, 'X-Requested-With': 'XMLHttpRequest' };
  } else {
    options = { headers: { 'X-CSRF-Token': csrfToken, 'X-Requested-With': 'XMLHttpRequest' } };
  }

  const response = await window.fetch(resource, options);

  if (response.status == 424) {
    window.bootstrap.Modal.getOrCreateInstance('#renew-verification-modal').show();
    // (window.bootstrap.Modal.getOrCreateInstance(document.getElementById('renew-verification-modal'))).show();
    return;
  }

  return response;
};

window.$Gunfinder.getDefaultHeaders = () => {
  return {
    'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
    'X-Requested-With': 'XMLHttpRequest',
  };
};
